@import './reset.scss';
@import './vars.scss';

// Sections
@import './sections/Hero';
@import './sections/About';
@import './sections/Resume';
@import './sections/Contact';

// Components
@import './components/Button';
@import './components/Resume-item';
@import './components/Filter-button';

// Font
body {
    font-family: 'Poppins', sans-serif;

    p {
        line-height: 1.5rem;
    }

    h2 {
        font-size: 2em;
    }

    h3 {
        font-size: 1.5em;
    }
    h4 {
        font-size: 1.25em;
    }

    ul {
        list-style-type: disc;
        margin-left: 1.25rem;
    }

    p {
        margin: 0.5rem 0;
    }

    a {
        color: $primary-dark;
        font-style: italic;
        text-decoration: none;
        a {
            color: $primary-light;
        }
    }
}

// Img
img {
    max-width: 100%;
}

// Layout
.container {
    display: flex;
    max-width: 1440px;
    padding: 0 2rem;
    margin: 0 auto;

    .col-1-2,
    .col-2-2 {
        width: 50%;
    }

    .col-1-2 {
        margin-right: 2.5rem;
    }

    .col-2-2 {
        margin-left: 2.5rem;
    }

    .col-1-3 {
        margin-right: 3.5%;
        width: 31%;

        &:nth-child(3n + 3) {
            margin-right: 0;
        }
    }
    @media (max-width: $break-point-mobile) {
        flex-wrap: wrap;
        padding: 0 1rem;

        .col-1-2,
        .col-2-2,
        .col-1-3 {
            width: 100%;
            margin-right: 0;
            margin-left: 0;
        }
    }
    @media (max-width: $break-point-medium) {
        flex-wrap: wrap;
        padding: 0 1rem;

        .col-1-2,
        .col-2-2 {
            width: 100%;
            margin-right: 0;
            margin-left: 0;
        }
    }
}

.section {
    padding: 8rem 0;
    position: relative;
    z-index: 2;
    background-color: #fff;
}

div {
    box-sizing: border-box;
}
