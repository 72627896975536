.filter {
    background-color: $primary-light;
    color: #fff;
    cursor: pointer;
    margin: 0.25rem;
    padding: 0.5rem 1rem;
    border-radius: 5rem;
    text-decoration: line-through;
    transition: all 300ms;
    &.active {
        text-decoration: none;
        background-color: $primary-dark;
        &:hover {
            background-color: $primary-light;
        }
    }
}
