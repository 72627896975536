#hero {
    align-items: center;
    background-image: linear-gradient(
        to bottom right,
        rgba($primary-dark, 0.85) 0%,
        rgba($primary-light, 0.85) 100%
    );
    background-position: right center;
    color: $grey-light;
    display: flex;
    height: 100vh;
    justify-content: center;
    overflow: hidden;
    position: relative;
    text-align: center;

    &:before {
        background-attachment: fixed;
        background-image: url(https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80);
        background-size: cover;
        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: -1;
    }
    .text-container {
        position: fixed;
        z-index: 0;
        @media (max-width: $break-point-mobile) {
            position: relative;
        }

        h1 {
            font-size: 5em;
            margin: 1rem 0;

            @media (max-width: $break-point-mobile) {
                font-size: 3em;
            }
        }
        h2 {
            font-size: 2em;
            margin-bottom: 3rem;
        }
    }

    .wave-container {
        position: absolute;
        bottom: 0;
        width: 100%;
        svg {
            display: block;
        }
    }
}
