.resume-item {
    background: #ffffff;
    border-radius: 5px;
    box-sizing: border-box;
    flex-direction: column;
    display: flex;
    margin: 0 1%;
    margin-bottom: 1.5rem;
    width: 31.33%;

    header {
        img {
            border-radius: $border-radius $border-radius 0 0;
            width: 100%;
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 1rem;
        p {
            margin-bottom: auto;
        }
    }

    &.open {
        width: 64.66%;
        header {
            img {
                display: none;
            }
        }
    }

    @media (max-width: $break-point-medium) {
        width: 48%;
        &.open {
            width: 100%;
        }
    }
    @media (max-width: $break-point-mobile) {
        margin-bottom: 1rem;
        width: 100%;
    }

    h3,
    h4 {
        margin-bottom: 0.5rem;
    }
    footer {
        align-self: flex-end;
        align-items: center;
        border-top: 1px solid $grey-light;
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;
        justify-content: space-between;
        padding-top: 0.75rem;
        width: 100%;
        div {
            span {
                display: block;
                &:last-child {
                    margin-top: 0.25rem;
                }
            }
        }
    }
}
