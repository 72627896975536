#contact {
    background-image: linear-gradient(
        to bottom right,
        rgba($primary-dark, 0.85) 0%,
        rgba($primary-light, 0.85) 100%
    );
    background-position: right center;
    color: $grey-light;
    display: flex;
    padding: 15rem 0 10rem;
    z-index: 9999;
    position: relative;

    .wave-container {
        transform: rotate(180deg);
        position: absolute;
        top: -10px;
        width: 100%;
    }

    .container {
        flex-direction: column;
        max-width: $max-content-width;
        text-align: center;

        h2 {
            font-size: 2em;
            margin-bottom: 2rem;
        }

        span {
            margin: 0.5rem 0;

            svg {
                margin-right: 1rem;
            }

            a {
                color: $grey-light;
                text-decoration: none;
                font-style: italic;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
