#about {
    padding-top: 0;

    @media (max-width: $break-point-mobile) {
        padding-top: 5rem;
    }

    .container {
        align-items: center;
    }

    h2 {
        margin-bottom: 2rem;
    }

    h3 {
        margin-top: 2rem;
    }
    h4 {
        margin: 1.5rem 0 1rem;
    }
    .skills {
        display: flex;
        flex-wrap: wrap;
        margin-left: -0.25rem;
        .skill {
            background-color: $primary-dark;
            color: #fff;
            margin: 0.25rem;
            padding: 0.5rem 1rem;
            border-radius: 5rem;
        }
    }

    img {
        border-radius: 50%;
        border: 3px solid $primary-light;
        width: 400px;
        @media (max-width: $break-point-medium) {
            margin: 3rem auto 0;
            display: block;
        }
    }
}
