#resume {
    background-color: $grey-light;

    .container {
        flex-direction: column;

        h2 {
            font-size: 2em;
            margin-bottom: 3rem;
        }
        .filters {
            background: #fff;
            border-radius: $border-radius;
            padding: 0.5rem 1rem;
            margin: 0 auto 2rem;
            width: fit-content;

            @media (max-width: $break-point-mobile) {
                padding: 1rem;
                width: 100%;
                span {
                    display: block;
                    margin-bottom: 0.5rem;
                }
            }
        }

        .experiences {
            display: flex;
            flex-wrap: wrap;
        }
    }
}
