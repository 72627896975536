.button {
    background-color: $primary-light;
    border-radius: 5rem;
    color: #fff;
    cursor: pointer;
    font-weight: bold;
    font-style: normal;
    font-size: 0.75rem;
    padding: 0.75rem 2rem;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    z-index: 99;

    &.light {
        background: transparent;
        border: 2px solid $grey-light;
        color: $grey-light;
    }
    &:hover {
        background-color: $grey-light;
        color: $primary-dark;
    }
}
