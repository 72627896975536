// Colors
$primary-dark: #4b6cb7;
$primary-light: #182848;
$grey-light: #cfdee7;

// size
$max-content-width: 768px;
$border-radius: 5px;

// Break Points
$break-point-mobile: 575.98px;
$break-point-medium: 1123.98px;
